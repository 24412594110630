<template>
    <section class="donate-section">
        <div class="container my-container">
            <div class="donate-content">
                <div class="row no-gutters">
                    <div class="col-md-6 half-content-container">
                        <img src="img/donate/img-donate.png" alt="" class="img-fluid">
                        <h3>Thank you for helping us grow.</h3>
                        <p>Your donation shall be used in maintaining and upgrading this website, including its server allocations. It will also be used in developing the QuiverShare mobile app which will help provide better usability and accessibility to its members.</p>
                        <div class="verses-container">
                            <h5><span class="dot"></span> Luke 6:38 NASB</h5>
                            <p>Give, and it will be given to you. They will pour into your lap a good measure—pressed down, shaken together, and running over. For by your standard of measure it will be measured to you in return.</p>
                        </div>
                    </div>
                    <div class="col-md-6 donate-half-container">
                        <div class="frequency-contianer">
                            <p>Select frequency</p>
                            <form action="" class="freq-form">
                                <input type="radio" class="btn-check" name="options-outlined" id="one-time" autocomplete="off" checked>
                                <label class="btn btn-outline-freq" for="one-time">One-time</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="weekly" autocomplete="off">
                                <label class="btn btn-outline-freq" for="weekly">Weekly</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="monthly" autocomplete="off">
                                <label class="btn btn-outline-freq" for="monthly">Monthly</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="annual" autocomplete="off">
                                <label class="btn btn-outline-freq" for="annual">Annually</label>
                            </form>
                        </div>
                        <div class="amount-contianer">
                            <p>Select Amount</p>
                            <form action="" class="amount-form">
                                <input type="radio" class="btn-check" name="options-outlined" id="amount1" autocomplete="off" checked value="10">
                                <label class="btn btn-outline-freq" for="amount1">$10</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="amount2" autocomplete="off" value="20">
                                <label class="btn btn-outline-freq" for="amount2">$20</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="amount3" autocomplete="off" value="30">
                                <label class="btn btn-outline-freq" for="amount3">$30</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="amount4" autocomplete="off" value="40">
                                <label class="btn btn-outline-freq" for="amount4">$40</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="amount5" autocomplete="off" value="50">
                                <label class="btn btn-outline-freq" for="amount5">$50</label>
                                <input type="radio" class="btn-check" name="options-outlined" id="amount6" autocomplete="off" value="0">
                                <label class="btn btn-outline-freq btn-other" for="amount6">Other</label>
                            </form>
                            <div class="collapse amount-collapse">
                                <div class="mb-1">
                                    <input type="text" placeholder="Enter amount" class="form-control" id="amount-donate">
                                </div>
                            </div>
                        </div>
                        <div class="mb-4 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label rem-label" for="exampleCheck1">Yes, I will cover the transaction fees.</label>
                        </div>

                        <div class="mb-4">
                            <label for="user_name" class="form-label">Name</label>
                            <input type="text" class="form-control" id="user_pass" placeholder="Enter name">
                        </div>

                        <div class="row no-gutters">
                            <div class="col-sm-6">
                                <div class="d-grid">
                                    <button class="btn btn-card" type="button">Give with Credit Card</button>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="d-grid">
                                    <button class="btn btn-paypal" type="button">Give with Paypal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>